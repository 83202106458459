import { API_URL } from "./http";

const addOrganization = () => `${API_URL}/organization`;

const refreshToken = () => `${API_URL}/refresh-token`;

const getSingleOrganization = () =>
  `${API_URL}/organization-admin/organization`;

const updateOrganization = () => `${API_URL}/organization-admin/organization`;

const getAllCountries = () => `${API_URL}/country`;

// const getAllSectors = () => `${API_URL}/sector`;

const getAllSectors = (partnerId) =>
  `${API_URL}/sector/get-all-sectors/${partnerId}`;

const getSubSectors = (sectorId) => `${API_URL}/sector/${sectorId}`;

const createMicrosite = () =>
  `${API_URL}/organization-admin/organization/microsite`;

const getAllMicrosites = () =>
  `${API_URL}/organization-admin/organization/microsite`;

const createContactUsSales = (micrositeId) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}/contact-us`;

const createContactUsAddress = (micrositeId) =>
  `${API_URL}/organization-admin/organization/microsite/contact-us/address?micrositeId=${micrositeId}`;

const updateContactUsSales = (id, micrositeId) =>
  `${API_URL}/organization-admin/organization/microsite/contact-us/${id}`;

const getContactUsDetails = (tabName, micrositeId) => {
  if (tabName === "REVIEWS") {
    return null;
  } else {
    return `${API_URL}/organization-admin/organization/microsite/${micrositeId}/contact-us?contactUsTypeEnum=${tabName}`;
  }
};

const getAboutUsDetails = (tabName, micrositeId) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}/about-us-details?aboutUsEnum=${tabName}`
const putAboutUsDetails = (micrositeId) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}/details/about-us`

// const getAllSkills = () => `${API_URL}/organization-admin/getSkills`;
const getAllSkills = () => `${API_URL}/get-skills`;

const publishMicrosite = (micrositeId) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}`;

const login = () => `${API_URL}/login`;

const forgotPassword = () => `${API_URL}/forgot-password`;

const changePassword = () => `${API_URL}/change-password`;

const help = () =>
  `${API_URL}/organization-admin/getHelpSection?userRole=ORGANIZATION_ADMIN`;

const getHelpByTab = (tab) =>
  `${API_URL}/organization-admin/getHelpSectionByTab?userRole=ORGANIZATION_ADMIN&${tab ? `assetType=${tab}&` : ""
  }`;

const getFilteredHelpInfo = (userRole, searchKey, tab) =>
  `${API_URL}/end-user/help-section/search/?userRole=${userRole}&text=${searchKey}&${tab ? `&assetType=${tab}` : ""}`


const createOrganizationUser = () => `${API_URL}/register-organization-admin`;

const referOrganisation = (partnerId, email) =>
  `${API_URL}/refer-organisation/${partnerId}?email=${email}`;

const createMicrositeTabs = (activateKey, micrositeId) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}/details/${activateKey}`;

const createApprenticeshipVacancies = (micrositeId, tabName) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}/${tabName}`;

const deleteApprenticeshipVacancies = (micrositeId, tabName, id) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}/${tabName}/${id}`;

const getMicrositeDetailsTabs = (tabName, micrositeId) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}/details?section=${tabName}`;

const updateMicrositeDetailsTabs = (tabName, micrositeId) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}/details/${tabName}`;

const deleteMicrositeDetailsTabs = (micrositeId, tabName, id) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}/details/${tabName}/${id}`;

const deleteContractTab = (id) =>
  `${API_URL}/organization-admin/organization/deleteContracts/${id}`;

const experienceOfWork = (micrositeId) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}/details/work-experience`;

const getMicrositeDetailsVacancyTab = (tabName, micrositeId) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}/vacancy?apprenticeshipType=${tabName}`;

const getMicrositeDetailsContractTab = (micrositeId) =>
  `${API_URL}/organization-admin/organization/contracts/${micrositeId}`;

const createContractDetails = (micrositeId) =>
  `${API_URL}/organization-admin/organization/addContracts/${micrositeId}`;

const updateContractDetails = (micrositeId) =>
  `${API_URL}/organization-admin/organization/updateContracts/${micrositeId}`;

const getPreviewExperienceofWorkTab = (micrositeId) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}/experience-of-work-toggled-on`;

const adminOrganization = () => `${API_URL}/organization-admin/organization`;

const getProfile = () => `${API_URL}/organization-admin/profile`;

const updateProfile = () => `${API_URL}/organization-admin/profile`;
const settings = (micrositeId) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}/site-settings`;
const getSettings = (partnerId, micrositeId) =>
  `${API_URL}/organization-admin/microsite/${micrositeId}/partner/pro-settings?partnerId=${partnerId}`;

const putSettings = (partnerId, micrositeId) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}/pro-settings?partnerId=${partnerId}`

const resetPassword = (token) =>
  `${API_URL}/reset-password/reset?token=${token}`;

const geteof = (micrositeId) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}/experience-of-work`;

const updateeof = (micrositeId, id) =>
  // `${API_URL}/organization-admin/organization/microsite/${micrositeId}/experience-of-work/${id}`;
  `${API_URL}/organization-admin/organization/microsite/experience-of-work/${id}`;

const geteofOP = () => `${API_URL}/experience-of-work-opportunity`;

const geteoftitle = () => `${API_URL}/get-work-experience-title`;

const geteofopportunitytitle = () =>
  `${API_URL}/experience-of-work-opportunity`;

const partnerDetails = (partnerId) =>
  `${API_URL}/organization-admin/organization-partner-details?${partnerId ? `partnerId=${partnerId}` : ""
  }`;

const getPartnerDetailsLoginScreen = (partnerId) =>
  `${API_URL}/login?${partnerId ? `partnerId=${partnerId}` : ""}`;

const getInsuranceFile = (micrositeId) =>
  `${API_URL}/organization-admin/insuranceDocs/${micrositeId}`;

const uploadInsuranceDoc = (micrositeId) =>
  `${API_URL}/organization-admin/uploadDocument/${micrositeId}`;

const feedbackForm = () => `${API_URL}/organization-admin/org-feedback`;

const deleteInsuranceDoc = (documentId) =>
  `${API_URL}/organization-admin/deleteDocument/${documentId}`;

const createMulMicrosite = () =>
  `${API_URL}/organization-admin/organization/microsite`;

const deleteMicrosite = (micrositeID) =>
  `${API_URL}/organization-admin/deleteMicrosite/${micrositeID}`;

const duplicateMicrosite = (micrositeID) =>
  `${API_URL}/organization-admin/newmicrosite/${micrositeID}`;

const getLevels = () => `${API_URL}/organization-admin/getlevels`;

const getReviews = (micrositeId) =>
  `${API_URL}/organization-admin/organization/microsite/${micrositeId}/getReviews`;

const replyReview = () =>
  `${API_URL}/organization-admin/organization/microsite/addReview`;

const deleteReview = (reviewId) =>
  `${API_URL}/organization-admin/organization/sendrequest/${reviewId}`;

const deleteReviewReply = (reviewId) =>
  `${API_URL}/end-user/organization/microsite/deleteReview/${reviewId}`;

const getTeamsCoursesProducts = (micrositeID, sectionName) =>
  `${API_URL}/organization-admin/microsite/${micrositeID}/get-section-titles?sectionName=${sectionName}`;

const saveTeamsCoursesProducts = (micrositeID, sectionName) =>
  `${API_URL}/organization-admin/microsite/${micrositeID}/section-title?sectionName=${sectionName}`;

const deleteTeamsCoursesProduct = (id) =>
  `${API_URL}/organization-admin/microsite-title/${id}`;

const deleteBrochure = (brochureId) =>
  `${API_URL}/end-user/delete-brochure/${brochureId}`;

const overallViewCount = (orgId) =>
  `${API_URL}/organization-admin/org-analytics/total/?organizationId=${orgId}`;

const orgAnalytics = (orgId, month, year, quarter) =>
  `${API_URL}/organization-admin/org-analytics/total-organization?organizationId=${orgId}${month ? `&month=${month}` : ""
  }${year ? `&year=${year}` : ""}${quarter ? `&quarter=${quarter}` : ""}`;

const locationAnalytics = (orgId, ageID, profID, filterType, year, month, quarter) =>
  `${API_URL}/organization-admin/organization-analytics/location?organizationId=${orgId}${ageID ? `&ageGroupId=${ageID}` : ""}
${profID ? `&tagId=${profID}` : ""}${filterType ? `&filterType=${filterType}` : ""}${year ? `&year=${year}` : ""}
${month ? `&month=${month}` : ""}${quarter ? `&quarter=${quarter}` : ""}`

const orgOverallMicrositeAnalytics = (orgId, month, year, quarter) =>
  `${API_URL}/organization-admin/org-analytics/microsite/monthly?organizationId=${orgId}${month ? `&month=${month}` : ""
  }${year ? `&year=${year}` : ""}${quarter ? `&quarter=${quarter}` : ""}`;

const getMicrositeAnalytics = (micrositeId, month, year, quarter) =>
  `${API_URL}/organization-admin/microsite/contactUs/total?micrositeId=${micrositeId}${month ? `&month=${month}` : ""
  }${year ? `&year=${year}` : ""}${quarter ? `&quarter=${quarter}` : ""}`;

const getProfessions = (ageGroupID) =>
  `${API_URL}/partner-admin/end-user-tags/age-group/${ageGroupID}`;

const getOnOrganisationType = (partnerId) =>
  `${API_URL}/get-all-orgType/${partnerId}?toggle=true`;

const downloadOrgEngagementCSV = (orgId) =>
  `${API_URL}/organization-admin/user-info/downloadCSV?organizationId=${orgId}`

export default {
  addOrganization,
  refreshToken,
  getAllCountries,
  getAllSectors,
  getSubSectors,
  getAllMicrosites,
  createMicrosite,
  createMicrositeTabs,
  createContactUsSales,
  updateContactUsSales,
  createContactUsAddress,
  getAllSkills,
  getSingleOrganization,
  updateOrganization,
  publishMicrosite,
  login,
  forgotPassword,
  createApprenticeshipVacancies,
  getMicrositeDetailsTabs,
  getMicrositeDetailsVacancyTab,
  getMicrositeDetailsContractTab,
  createContractDetails,
  updateContractDetails,
  getPreviewExperienceofWorkTab,
  getContactUsDetails,
  getAboutUsDetails,
  putAboutUsDetails,
  adminOrganization,
  getProfile,
  updateProfile,
  changePassword,
  help,
  getHelpByTab,
  getFilteredHelpInfo,
  createOrganizationUser,
  experienceOfWork,
  deleteApprenticeshipVacancies,
  updateMicrositeDetailsTabs,
  deleteMicrositeDetailsTabs,
  deleteContractTab,
  settings,
  getSettings,
  putSettings,
  referOrganisation,
  resetPassword,
  geteof,
  updateeof,
  geteofOP,
  geteoftitle,
  geteofopportunitytitle,
  partnerDetails,
  getPartnerDetailsLoginScreen,
  uploadInsuranceDoc,
  feedbackForm,
  getInsuranceFile,
  deleteInsuranceDoc,
  createMulMicrosite,
  deleteMicrosite,
  duplicateMicrosite,
  getLevels,
  getReviews,
  replyReview,
  deleteReview,
  deleteReviewReply,
  getTeamsCoursesProducts,
  saveTeamsCoursesProducts,
  deleteTeamsCoursesProduct,
  deleteBrochure,
  overallViewCount,
  orgAnalytics,
  locationAnalytics,
  orgOverallMicrositeAnalytics,
  getMicrositeAnalytics,
  getProfessions,
  getOnOrganisationType,
  downloadOrgEngagementCSV
};
